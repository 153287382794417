import React, { useRef, useState } from 'react';
import { 
    Container, 
    Card, 
    CardBody, 
    CardFooter, 
    CardHeader, 
    Row, 
    Col,
    Button,
    Alert
} from 'reactstrap';
import AuthHandler from '../classes/AuthHandler';
import LoadingButton from '../components/LoadingButton';
import WithPassword from '../components/login/WithPassword';
import { useLocation, useNavigate } from 'react-router-dom';

type UserAuthType = { username?: string, password?: string } | undefined;

type AuthRefHandler = {
    getLoginInfo: () => UserAuthType
}

const Login = () => {
    const [alert, setAlert] = useState('');
    const [loggingIn, setLoggingIn] = useState(false);
    const location = useLocation()
    const withPassRef = useRef<AuthRefHandler>(null);
    const navigate = useNavigate();

    const onLogin = async () => {
        try {
            setLoggingIn(true);
            const authParams = withPassRef.current?.getLoginInfo();
            const authHandler = new AuthHandler();
            const authResp = await authHandler.login(authParams?.username, authParams?.password);
            setLoggingIn(false);
            sessionStorage.setItem("accessToken", authResp.accessToken);
            sessionStorage.setItem("refreshToken", authResp.refreshToken);
            sessionStorage.setItem("baseUrl", authResp.user.serverUrl);
            navigate('/payment/' + location.search, { state: authResp.user.id });
        } catch(e) {
            if (e instanceof Error) {
                setAlert(e.message);
            } else if (typeof e === 'string') {
                setAlert(e);
            }
            setLoggingIn(false);
        }
    }

    return (
        <Container className='my-5'>
            <Row>
                <Col lg='6' className='mx-auto'>
                    <Card>
                        <CardHeader>
                            <img src={require('../assets/logo.png')} height={60} alt='logo' />
                        </CardHeader>
                        <CardBody className='p-3'>
                            <h4><strong>Welcome Back</strong></h4>
                            <WithPassword ref={withPassRef} />
                            {
                                !!alert && <Alert className='mt-2' color='danger' toggle={() => setAlert('')}>{alert}</Alert>
                            }
                        </CardBody>
                        <CardFooter>
                            <LoadingButton onClick={onLogin} className='mt-2' loading={loggingIn} disabled={loggingIn}>
                                <h6 className='mb-0 text-white'>Sign In</h6>
                            </LoadingButton>
                            <div className='d-flex justify-content-center align-items-center'>
                                <small>Don't have an account?</small>
                                <Button color='link'><a href='/sign-up' style={{ color: '#3D5A80', fontWeight: 'bold' }}>Sign Up</a></Button>
                            </div>
                        </CardFooter>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default Login;