import { AxiosError } from "axios";

type ErrorResponseData = {
    message: string
}
export default class ExceptionHandler {

    getUserFriendlyMessage = (e: any) => {
        if (typeof e === 'string') {
            return e;
        } else if (e instanceof Error) {
            if ('response' in e) {
                return ((e as AxiosError).response?.data as ErrorResponseData).message;
            }
            return e.message;
        }
    }
}