import React, { useEffect, useImperativeHandle, useState } from 'react';
import { Input } from 'reactstrap';
import { SUBSCRIBE_CL_OPTIONS } from '../../consts/SubscribeOptions';

type Props = {
    className: string,
    totalCount: number | undefined,
    subscriptionId: string | undefined
}

const PurchaseCLItems = React.forwardRef(({ totalCount, subscriptionId, ...rest }: Props, ref) => {
    const [count, setCount] = useState(50);

    useEffect(() => {
        if (subscriptionId && totalCount) {
            setCount(totalCount);
        }
    }, [totalCount, subscriptionId]);

    useImperativeHandle(ref, () => ({
        getItemCount: () => count
    }));

    return (
        <div {...rest}>
            <h5 className='d-block' style={{ color: '#3D5A80' }}>Select Monthly Plan</h5>
            <Input type='select' className='mt-2' style={{ fontFamily: 'Nunito' }} value={count} onChange={e => setCount(parseInt(e.target.value))}>
                {
                    SUBSCRIBE_CL_OPTIONS.map(sOption => (
                        <option key={sOption.count} value={sOption.count}>${(sOption.price / 100).toFixed(2)} for {sOption.count} crosslists</option>
                    ))
                }
            </Input>
        </div>
    )
})

export default PurchaseCLItems;