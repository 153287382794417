import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Card, Progress } from 'reactstrap';

type Props = {
    startedAt: string | undefined,
    endAt: string | undefined,
    totalCount: number | undefined,
    usedCount: number | undefined,
    className: string
}

const CurrentUsage = ({ startedAt, endAt, totalCount, usedCount, ...rest }: Props) => {
    const [progVal, setProgVal] = useState(0);

    useEffect(() => {
        if (!totalCount || !usedCount) {
            setProgVal(0);
        } else {
            setProgVal(usedCount * 100 / totalCount);
        }
    }, [totalCount, usedCount]);

    return (
        <Card {...rest}>
            <h6 className='mb-0' style={{ color: '#3D5A80' }}>Current Usage</h6>
            {
                !!startedAt && !!endAt &&
                <small style={{ color: '#3D5A80', marginTop: 4 }}>{moment(startedAt).format('MMM DD, yyyy')} - {moment(endAt).format('MMM DD, yyyy')} at {moment(new Date()).format('hh:mm a')}</small>
            }
            
            <Progress value={progVal} className='w-100 mt-2' barClassName='bar-fill-color' />
            <div className='d-flex w-100 justify-content-between'>
                <small style={{ color: '#3D5A80' }}>0</small>
                <small style={{ color: '#3D5A80' }}>{totalCount}</small>
            </div>
        </Card>
    )
}

export default CurrentUsage;