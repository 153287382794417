import React, { useEffect, useRef, useState } from 'react';
import { 
    Container, 
    Row, 
    Col, 
    Card, 
    CardHeader, 
    CardBody, 
    CardFooter, 
    Alert, 
    Button 
} from 'reactstrap';
import LoadingButton from '../components/LoadingButton';
import SignUpForm from '../components/signup/SignUpForm';
import AuthHandler from '../classes/AuthHandler';
import UserInfo from '../interfaces/UserInfo';
import { useLocation } from 'react-router-dom';

type SignUpFormRefHandler = {
    getSignUpDetails: () => UserInfo | undefined,
    isAgreedTermsPolicy: () => Boolean,
    setReferredCode: (refCode: string) => void
}

const SignUp = () => {
    const [signingUp, setSigningUp] = useState(false);
    const [alert, setAlert] = useState('');
    const [success, setSuccess] = useState(false);
    const location = useLocation();
    const formRef = useRef<SignUpFormRefHandler>(null);

    useEffect(() => {
        const searchStr = location.search;
        const refCode = searchStr?.split("=")[1];
        if (searchStr.includes("refCode") && !!refCode) {
            formRef.current?.setReferredCode(refCode);
        }
    }, [location]);

    const onSignUp = async () => {
        try {
            if (!formRef.current?.isAgreedTermsPolicy()) {
                setAlert('You have to agree our terms and policy to using our service');
                return 
            }
            setSigningUp(true);
            const authHandler = new AuthHandler();
            const userInfo = formRef.current?.getSignUpDetails();
            if (!userInfo) return;
            await authHandler.userRegister(userInfo);
            setSigningUp(false);
            setSuccess(true);
        } catch(e) {
            if (e instanceof Error) {
                setAlert(e.message);
            } else if (typeof e === 'string') {
                setAlert(e);
            }
            setSigningUp(false);
        }
    }

    return (
        <Container className='my-5'>
            <Row>
                <Col lg='6' className='mx-auto'>
                    <Card>
                        <CardHeader>
                            <img src={require('../assets/logo.png')} height={60} />
                        </CardHeader>
                        <CardBody className='p-3'>
                            <h4><strong>Create an Account</strong></h4>
                            <SignUpForm ref={formRef} />
                            {
                                !!alert && <Alert color='danger' className='mt-2' toggle={() => setAlert('')}>{alert}</Alert>
                            }
                            {
                                success && <Alert color='success' className='mt-2' toggle={() => setSuccess(false)}>Confirmation email sent to your inbox</Alert>
                            }
                        </CardBody>
                        <CardFooter>
                            <LoadingButton onClick={onSignUp} className='mt-2' loading={signingUp} disabled={signingUp}>
                                <h6 className='mb-0 text-white'>Sign Up</h6>
                            </LoadingButton>
                            <div className='d-flex justify-content-center align-items-center'>
                                <small>Already have an account?</small>
                                <Button color='link'><a href='/' style={{ color: '#3D5A80', fontWeight: 'bold' }}>Sign In</a></Button>
                            </div>
                        </CardFooter>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default SignUp;