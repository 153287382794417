export const enum Endpoints {
    USER_LOGIN = 'userLogin',
    USER_REGISTER = 'userRegister',
    CREATE_SUBSCRIPTION = 'createSubscription',
    UPDATE_SUBSCRIPTION = 'updateSubscription',
    UNSUBSCRIBE = 'unsubscribe',
    GET_STRIPE_SUBSCRIPTION = 'getStripeSubscription',
    GET_COUPON_DETAILS = 'getCouponDetails',
    APPLY_COUPON = 'applyCoupon',
    GET_USER_DETAILS = 'getUserDetails'
}