import APIHandler from "./APIHandler";
import UserInfo from "../interfaces/UserInfo";
import ExceptionHandler from "./ExceptionHandler";
import validator from 'validator';
import { Endpoints } from "../consts/Endpoints";
import AuthSuccess from "../interfaces/AuthSuccess";

class AuthHandler extends ExceptionHandler {
    readonly apiHandler: APIHandler<AuthSuccess | UserInfo>;
    constructor() {
        super();
        this.apiHandler = new APIHandler(process.env.REACT_APP_AUTH_SERVER_URL as string, false);
    }

    private validEmail = (email?: string): boolean => {
        if (!email || !validator.isEmail(email)) {
            return false;
        }
        return true;
    }

    private validFullName = (fullName?: string) => {
        if (!fullName) return false;
        var fullNameRegex = /^[a-zA-Z ]+$/;
        return fullNameRegex.test(fullName);
    }

    private validUsername = (username?: string) => {
        if (!username) return false;
        var usernameRegex = /^[a-z0-9_]+$/;
        return usernameRegex.test(username);
    }

    userRegister = async (userInfo: UserInfo) => {
        if (!userInfo.fullName || !this.validFullName(userInfo.fullName)) {
            throw new Error('Invalid full name detected');
        }
        if (!this.validUsername(userInfo.username)) {
            throw new Error('Invalid username. Username must be all lowercased');
        }
        if (!this.validEmail(userInfo.email)) {
            throw new Error('Invalid email detected');
        }
        if (!userInfo.password) {
            throw new Error('Invalid password detected');
        }
        if (userInfo.password !== userInfo.confirmPassword) {
            throw new Error('Password does not match');
        }
        try {
            const user = await this.apiHandler.invokePOST(Endpoints.USER_REGISTER, userInfo)
            return user as UserInfo;
        } catch(e) {
            throw new Error(this.getUserFriendlyMessage(e));
        }
    }

    login = async (username?: string, password?: string) => {
        if (!this.validUsername(username) && !this.validEmail(username)) {
            throw new Error('Invalid username or email detected');
        }
        if (!password) {
            throw new Error('Invalid password detected');
        }
        try {
            const user = await this.apiHandler.invokePOST(Endpoints.USER_LOGIN, { username, password })
            return user as AuthSuccess;
        } catch(e) {
            throw new Error(this.getUserFriendlyMessage(e));
        }
    }
}

export default AuthHandler;