import { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Input, Alert } from "reactstrap";
import ReactFlagsSelect from 'react-flags-select';

import {
    CardElement,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
import LoadingButton from "../LoadingButton";
import BillingInfo from "../../interfaces/BillingInfo";
import { StripeCardElement } from "@stripe/stripe-js";
import Coupon from "../../interfaces/Coupon";
import PaymentHandler from "../../classes/PaymentHandler";
import PaymentIntent from "../../interfaces/PaymentIntent";
import { SUBSCRIBE_CL_OPTIONS, SUBSCRIBE_RELISTING_OPTIONS, SUBSCRIBE_MG_OPTIONS, SUBSCRIBE_TYPES } from "../../consts/SubscribeOptions";

type Props = {
    isOpen: boolean,
    itemCount: number,
    userId?: number | undefined,
    customerId?: string | undefined,
    subscriptionType?: string | undefined,
    email?: string | undefined,
    phone?: string | undefined,
    productId?: string | undefined,
    toggle?: () => void,
    onCreatedSubscription: (subscriptionId: string, subscriptionType: string | undefined, itemCount: number, amountPaid: number) => void
}

const PaymentModal = (props: Props) => {
    const [alert, setAlert] = useState('');
    const [billingInfo, setBillingInfo] = useState<BillingInfo>({ fullName: '', city: '', address1: '', address2: '', state: '', zipCode: '', country: 'US', coupon: '' });
    const [couponDetails, setCouponDetails] = useState<Coupon | null>(null);
    const [paying, setPaying] = useState(false);
    const stripe = useStripe();
    const elements = useElements();

    const getSubscriptionFee = () => {
        if (props.subscriptionType === SUBSCRIBE_TYPES.crosslisting) {
            return SUBSCRIBE_CL_OPTIONS.find(sOption => sOption.count === props.itemCount)?.price;
        } else if (props.subscriptionType === SUBSCRIBE_TYPES.relisting) {
            return SUBSCRIBE_RELISTING_OPTIONS.find(sOption => sOption.count === props.itemCount)?.price;
        } else if (props.subscriptionType === SUBSCRIBE_TYPES.magiscriptor) {
            return SUBSCRIBE_MG_OPTIONS.find(sOption => sOption.count === props.itemCount)?.price;
        }
    }

    const getTotalPrice = () => {
        let totalPrice = getSubscriptionFee() ?? 0;
        if (couponDetails) {
            totalPrice = totalPrice * (100 - couponDetails.percent_off) / 100;
        }
        return totalPrice;
    }

    const getModalTitle = () => {
        if (props.subscriptionType === SUBSCRIBE_TYPES.crosslisting) {
            return "Crosslisting subscription";
        } else if (props.subscriptionType === SUBSCRIBE_TYPES.relisting) {
            return "Relisting subscription";
        } else if (props.subscriptionType === SUBSCRIBE_TYPES.magiscriptor) {
            return "Magiscriptor subscription";
        }
    }

    const onClickPayNow = async () => {
        if (!stripe || !elements) {
            return;
        }
        if (!billingInfo.fullName) {
            setAlert('Invalid full name');
            return;
        }
        if (!billingInfo.city) {
            setAlert('Invalid city');
            return;
        }
        if (!billingInfo.address1) {
            setAlert('Invalid address');
            return;
        }
        if (!billingInfo.state) {
            setAlert('Invalid state');
            return;
        }
        if (!billingInfo.zipCode) {
            setAlert('Invalid zip code');
            return;
        }
        // create subscription
        fetchPaymentIntentClientSecret();
    }

    const fetchPaymentIntentClientSecret = async () => {
        if (!stripe || !elements) {
            return;
        }
        if (!props.userId) {
            return;
        }
        try {
            setPaying(true);
            const paymentHandler = new PaymentHandler<PaymentIntent>();
            const paymentIntent = await paymentHandler.fetchPaymentIntentClientSecret(props.userId, props.subscriptionType, props.itemCount, billingInfo.coupon);
            if (paymentIntent.clientSecret) {
                const paymentResult = await stripe?.confirmCardPayment(paymentIntent.clientSecret, { 
                    payment_method: {
                        card: elements.getElement('card') as StripeCardElement,
                        billing_details: {
                            address: {
                                city: billingInfo.city, 
                                country: billingInfo.country, 
                                line1: billingInfo.address1, 
                                line2: billingInfo.address2, 
                                state: billingInfo.state 
                            },
                            name: billingInfo.fullName,
                            email: props.email,
                            phone: props.phone
                        }
                    },
                    setup_future_usage: 'off_session'
                })
                
                setPaying(false);
                if (paymentResult.error) {
                    setAlert(paymentResult.error.message ?? 'Payment error');
                    return;
                }
                if (paymentResult.paymentIntent) {
                    props.onCreatedSubscription(paymentIntent.subscriptionId, props.subscriptionType, props.itemCount, paymentResult.paymentIntent?.amount as number);
                }
            } else if (paymentIntent.paid) {
                setPaying(false);
                const amountPaid = getTotalPrice();
                props.onCreatedSubscription(paymentIntent.subscriptionId, props.subscriptionType, props.itemCount, amountPaid);
            }
        } catch (e) {
            setPaying(false);
            if (e instanceof Error) {
                setAlert(e.message);
            } else if (typeof e === 'string') {
                setAlert(e);
            }
        }
    }

    const getCouponDetails = async () => {
        if (billingInfo.coupon) {
            try {
                const paymentHandler = new PaymentHandler<Coupon>();
                const coupon = await paymentHandler.getCouponDetails(billingInfo.coupon);
                setCouponDetails(coupon);
            } catch(e) {
                if (e instanceof Error) {
                    setAlert(e.message);
                } else if (typeof e === 'string') {
                    setAlert(e);
                }
            }
        } else {
            setCouponDetails(null);
        }
    }

    return (
        <Modal isOpen={props.isOpen} toggle={props.toggle} centered>
            <ModalHeader className='justify-content-center'>
                {getModalTitle()}
            </ModalHeader>
            <ModalBody>
                <small><strong>Billing Info</strong></small>
                <Input placeholder="Full Name" className="mt-2" value={billingInfo.fullName} onChange={e => setBillingInfo(prev => ({ ...prev, fullName: e.target.value }))} />
                <Input placeholder="City" className="mt-2" value={billingInfo.city} onChange={e => setBillingInfo(prev => ({ ...prev, city: e.target.value }))} />
                <Input placeholder="Address 1" className="mt-2" value={billingInfo.address1} onChange={e => setBillingInfo(prev => ({ ...prev, address1: e.target.value }))} />
                <Input placeholder="Address 2 (Optional)" className="mt-2" value={billingInfo.address2} onChange={e => setBillingInfo(prev => ({ ...prev, address2: e.target.value }))} />
                <div className="d-flex justify-content-between mt-2">
                    <Input placeholder="State"  value={billingInfo.state} onChange={e => setBillingInfo(prev => ({ ...prev, state: e.target.value }))} />
                    <Input placeholder="Zip Code" className="ms-2" value={billingInfo.zipCode} onChange={e => setBillingInfo(prev => ({ ...prev, zipCode: e.target.value }))}/>
                </div>
                <ReactFlagsSelect 
                    className="mt-2"
                    selected={billingInfo.country}
                    onSelect={(country) => setBillingInfo(prev => ({ ...prev, country }))}
                />
                <Input placeholder="Coupon Code (Optional)" 
                    className="mt-2" 
                    value={billingInfo.coupon} 
                    onChange={e => setBillingInfo(prev => ({ ...prev, coupon: e.target.value }))} 
                    onBlur={getCouponDetails} />
                <div className="mt-2">
                    <small><strong>Card Details</strong></small>
                </div>
                <CardElement 
                    className='mt-2 bg-white p-2 border text-muted rounded'
                    options={{
                        hidePostalCode: true,
                        style: {
                            base: {
                                fontSize: '16px',
                                color: '#8898aa',
                                fontFamily: 'Nunito',
                                fontWeight: 400,
                                '::placeholder': {
                                    color: '#8898aa',
                                }
                            }
                        }
                    }} />
                <hr />
                <small><strong>Order Summary</strong></small>
                <div className="d-flex justify-content-between mt-2">
                    <small>Subscription Fee</small>
                    <small>${((getSubscriptionFee() ?? 0) / 100).toFixed(2)}</small>
                </div>
                {
                    !!couponDetails &&
                    <div className="d-flex justify-content-between mt-2">
                        <small>Coupon Discount</small>
                        <small>-{couponDetails.percent_off}%</small>
                    </div>
                }
                <div className="d-flex justify-content-between mt-2">
                    <small>Total</small>
                    <small>${(getTotalPrice() / 100).toFixed(2)}</small>
                </div>
                {
                    !!alert && <Alert className="mt-2" color="danger" toggle={() => setAlert('')}>{alert}</Alert>
                }
            </ModalBody>
            <ModalFooter className="justify-content-center">
                <LoadingButton onClick={onClickPayNow} loading={paying} disabled={paying}>Pay Now</LoadingButton>
            </ModalFooter>
        </Modal>
    )
}

export default PaymentModal;