import { useState } from 'react';
import { Card, FormFeedback, Input, InputGroup } from 'reactstrap';
import { Invoice, PaymentDetails } from '../../interfaces/Subscription';
import LoadingButton from '../LoadingButton';
import { upperFirst } from 'lodash';
import { SUBSCRIBE_TYPES } from '../../consts/SubscribeOptions';
import PaymentHandler from '../../classes/PaymentHandler';
import Coupon from '../../interfaces/Coupon';
import moment from 'moment';

type Props = {
    isExpired: Boolean,
    isTrial: Boolean,
    amountPaid: number | undefined,
    totalCount: number | undefined,
    paymentDetails: PaymentDetails | undefined,
    subscriptionType: string | undefined,
    upcomingInvoice: Invoice | undefined | null,
    subscriptionId: string | undefined,
    onUnsubscribe: () => void,
    onCouponApplied: () => void
}
const SubscriptionCard = ({ isExpired, isTrial, totalCount, amountPaid, paymentDetails, subscriptionType, upcomingInvoice, subscriptionId, onUnsubscribe, onCouponApplied }: Props) => {
    const [coupon, setCoupon] = useState("");
    const [isCouponValid, setIsCouponValid] = useState(false);
    const [couponErr, setCouponErr] = useState("");
    const [isApplying, setIsApplying] = useState(false);

    const onApplyCoupon = async () => {
        try {
            if (!coupon || !subscriptionId) return;
            setIsApplying(true);
            const paymentHandler = new PaymentHandler<Coupon>();
            await paymentHandler.getCouponDetails(coupon);
            
            setIsCouponValid(true);
            await paymentHandler.applyCoupon(subscriptionId, coupon)
            onCouponApplied()
        } catch (e) {
            if (e instanceof Error) {
                setCouponErr(e.message);
            } else if (typeof e === 'string') {
                setCouponErr(e);
            }
        } finally {
            setIsApplying(false)
        }
    }

    return (
        <Card className='border-radius pb-4 overflow-hidden' style={{ background: isExpired ? 'white' : 'linear-gradient(90deg, #3D5A80, #5075A5)' }}>
            {
                isExpired &&
                <div style={{ backgroundColor: '#FF4B4E' }}>
                    <small style={{ color: 'white' }}>Subscription Expired</small>
                </div>
            }
            <div className='py-2 mt-4' style={{ background: isExpired ? '#3D5A80' : 'white', width: '40%', borderTopRightRadius: 8, borderBottomRightRadius: 8 }}>
                <h6 className='mb-0' style={{ color: isExpired ? '#fff' : '#3D5A80' }}>Current Subscription</h6>
            </div>
            <div className='d-flex justify-content-between px-4 mt-4'>
                {
                    !!isTrial &&
                    <h6 className='mb-0' style={{ color: isExpired ? '#3D5A80' : 'white', fontSize: 24, fontWeight: 'bold' }}>Trial</h6>
                }
                {
                    !isTrial &&
                    <div className='text-start'>
                        <h6 className='mb-0' style={{ color: isExpired ? '#3D5A80' : 'white', fontSize: 24, fontWeight: 'bold' }}>{!!amountPaid ? `$${(amountPaid / 100).toFixed(2)}` : '$0'} / mon</h6>
                        {
                            !!upcomingInvoice?.next_payment_attempt && 
                            <small style={{ color: isExpired ? '#3D5A80' : 'white' }}>
                                Renewal at {moment(upcomingInvoice?.next_payment_attempt * 1000).format('MMM DD, yyyy')} for ${(upcomingInvoice.amount_due / 100).toFixed(2)}
                            </small>
                        }
                    </div>
                }
                <div>
                    <h6 className='mb-0' style={{ color: isExpired ? '#3D5A80' : 'white', fontSize: 24, fontWeight: 'bold' }}>{totalCount}</h6>
                    <h6 style={{ color: isExpired ? '#3D5A80' : 'white' }}>{subscriptionType === SUBSCRIBE_TYPES.relisting ? 'Relistings / day' : 'Items'}</h6>
                </div>
            </div>
            {
                !isExpired && !isTrial && 
                <div className='px-4 mt-2 d-flex justify-content-between align-items-center'>
                    <LoadingButton className='px-0 text-start bg-transparent' onClick={onUnsubscribe}><i>Unsubscribe</i></LoadingButton>
                    {
                        !!paymentDetails && <h6 className='text-white'><strong>{upperFirst(paymentDetails.card.brand)}</strong> ending with {paymentDetails.card.last4}</h6>
                    }
                    
                </div>
            }
            {
                !isExpired && !isTrial &&
                <div className='d-flex align-items-center px-4 my-2'>
                    <InputGroup>
                        <Input placeholder='Input Coupon Code' value={coupon} valid={isCouponValid} invalid={!!couponErr} onChange={e => {
                            setCouponErr("");
                            setCoupon(e.target.value)
                        }} />
                        {!!couponErr && <FormFeedback tooltip>{couponErr}</FormFeedback>}
                        <LoadingButton className='w-auto' onClick={onApplyCoupon} loading={isApplying}>
                            Apply Coupon
                        </LoadingButton>
                    </InputGroup>
                </div>
            }
        </Card>
    )
}

export default SubscriptionCard;