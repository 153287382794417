import React from 'react';
import { Button, Spinner } from 'reactstrap';
import classnames from "classnames";
import styled from 'styled-components';

type Props = {
    onClick?: () => void,
    className?: string,
    loading?: boolean,
    color?: string,
    children?: React.ReactNode,
    disabled?: boolean,
    style?: object
}
const LoadingButton = ({ loading, children, ...rest }: Props) => {
    return (
        <Button {...rest} disabled={!!loading || rest.disabled}>
            {loading && (
            <Spinner
                className={classnames({
                    "position-relative": true,
                    visible: loading,
                    invisible: !loading
                    })}
                size="sm"
                // type="grow"
            />
            )}
            {!loading && (
            <span
                className={classnames({
                    invisible: loading,
                    visible: !loading
                })}
            >
                {children}
            </span>
            )}
        </Button>
    )
}

export default styled(LoadingButton)`
    background-color: #3D5A80;
    border: 0px;
    width: 50%;
    padding-top: 8px;
    padding-bottom: 8px;
    &: hover {
        background-color: #3D5A80A0;
    }
`