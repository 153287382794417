import APIHandler from "./APIHandler";
import ExceptionHandler from "./ExceptionHandler";
import PaymentIntent from "../interfaces/PaymentIntent";
import Subscription from "../interfaces/Subscription";
import { Endpoints } from "../consts/Endpoints";
import Coupon from "../interfaces/Coupon";

type PaymentResponses = PaymentIntent | Subscription[] | Coupon;
export default class PaymentHandler<T extends PaymentResponses> extends ExceptionHandler {
    readonly apiHandler: APIHandler<T>;
    constructor() {
        super();
        const baseUrl = sessionStorage.getItem("baseUrl") as string;
        this.apiHandler = new APIHandler<T>(baseUrl);
    }

    fetchPaymentIntentClientSecret = async (userId: number, subscriptionType: string | undefined, itemCount: number, couponCode?: string) => {
        try {
            let params = {
                userId,
                subscriptionType,
                itemCount,
                couponCode
            }
            const paymentIntent = await this.apiHandler.invokePOST(Endpoints.CREATE_SUBSCRIPTION, params)
            return paymentIntent as PaymentIntent;
        } catch(e) {
            throw new Error(this.getUserFriendlyMessage(e));
        }
    }

    getSubscription = async (userId: number) => {
        try {
            const params = {
                userId
            }
            const subscriptions = await this.apiHandler.invokeGET(Endpoints.GET_STRIPE_SUBSCRIPTION, params);
            return subscriptions as [Subscription];
        } catch(e) {
            throw new Error(this.getUserFriendlyMessage(e));
        }
    }

    getCouponDetails = async (coupon: string) => {
        try {
            if (!coupon) {
                throw new Error('Coupon could not be null');
            }
            const params = {
                coupon
            }
            const couponDetails = await this.apiHandler.invokeGET(Endpoints.GET_COUPON_DETAILS, params);
            return couponDetails as Coupon;
        } catch(e) {
            throw new Error(this.getUserFriendlyMessage(e));
        }
    }

    applyCoupon = async (subscriptionId: string, coupon: string) => {
        try {
            const params = {
                subscriptionId,
                coupon
            }
            await this.apiHandler.invokePOST(Endpoints.APPLY_COUPON, params);
        } catch(e) {
            throw new Error(this.getUserFriendlyMessage(e));
        }
    }

    upgradeSubscription = async (userId: number, subscriptionId: string, subscriptionType: string, itemCount: number) => {
        try {
            if (!subscriptionId) {
                throw new Error('Subscription Id is required');
            }
            if (!itemCount) {
                throw new Error('Item count is required');
            }
            const params = {
                userId,
                subscriptionId,
                itemCount,
                subscriptionType
            };
            await this.apiHandler.invokePOST(Endpoints.UPDATE_SUBSCRIPTION, params);
        } catch(e) {
            throw new Error(this.getUserFriendlyMessage(e));
        }
    }

    cancelSubscription = async (userId: number, subscriptionType: string) => {
        try {
            if (!userId) {
                throw new Error('User Id is required');
            }
            const params = {
                userId,
                subscriptionType
            }
            await this.apiHandler.invokePOST(Endpoints.UNSUBSCRIBE, params);
        } catch(e) {
            throw new Error(this.getUserFriendlyMessage(e));
        }
    }
}