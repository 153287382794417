import React, { useImperativeHandle, useState } from 'react';
import { Input, Label } from 'reactstrap';

const SignUpForm = React.forwardRef((props, ref) => {
    const [fullName, setFullName] = useState('');
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [referredCode, setReferredCode] = useState('');
    const [isFromAffLink, setIsFromAffLink] = useState(false);
    const [disabledReferral, setDisabledReferral] = useState(false);
    const [agreedTerms, setAgreedTerms] = useState(false);

    useImperativeHandle(ref, () => ({
        getSignUpDetails: () => ({ fullName, username, email, password, confirmPassword, referredCode, isFromAffLink }),
        isAgreedTermsPolicy: () => agreedTerms,
        setReferredCode: (refCode: string) => {
            if (refCode) {
                setReferredCode(refCode)
                setDisabledReferral(true);
                setIsFromAffLink(true);
            }
        }
    }));

    return (
        <>
            <Input className='mt-3' placeholder='Full Name' value={fullName} onChange={e => setFullName(e.target.value)} />
            <Input className='mt-3' placeholder='Username' value={username} onChange={e => setUsername(e.target.value)} />
            <Input className='mt-3' placeholder='Enter your email' value={email} onChange={e => setEmail(e.target.value)} />
            <Input className='mt-3' placeholder='Enter your password' type='password' value={password} onChange={e => setPassword(e.target.value)} />
            <Input className='mt-3' placeholder='Confirm your password' type='password' value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} />
            <Input className='mt-3' placeholder='Enter your referral code' value={referredCode} onChange={e => setReferredCode(e.target.value)} disabled={disabledReferral} />
            <Label check className='mt-3 d-flex align-items-start'>
                <Input type='checkbox' checked={agreedTerms} onChange={e => setAgreedTerms(prev => !prev)} />&nbsp;I agree to the&nbsp;
                    {<a href="https://crosslisting.com/terms/" rel="noreferrer" target="_blank" style={{ color: '#3D5A80' }}>Terms of Service</a>}&nbsp;and&nbsp;
                    {<a href="https://crosslisting.com/privacy/" rel="noreferrer" target="_blank" style={{ color: '#3D5A80' }}>Privacy Policy</a>}
            </Label>
        </>
    )
})

export default SignUpForm;