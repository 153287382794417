
import axios, { AxiosInstance } from "axios";
import { Endpoints } from "../consts/Endpoints";
import UserInfo from "../interfaces/UserInfo";
import PaymentIntent from "../interfaces/PaymentIntent";
import Subscription from "../interfaces/Subscription";
import AuthSuccess from "../interfaces/AuthSuccess";
import Coupon from "../interfaces/Coupon";
import validator from 'validator';
import jwtDecode from 'jwt-decode';

type ResponseType = AuthSuccess |
                    UserInfo | 
                    PaymentIntent |
                    Subscription[] | 
                    Coupon;

class APIHandler<T extends ResponseType> {
    readonly apiInstance: AxiosInstance;

    constructor(baseUrl: string, requireAuthorization: Boolean = true) {
        this.apiInstance = axios.create({
            baseURL: baseUrl,
            timeout: 60000
        });
        if (requireAuthorization) {
            this.apiInstance.interceptors.request.use(async config => {
                let accessToken = sessionStorage.getItem("accessToken");
                const refreshToken = sessionStorage.getItem("refreshToken");
                if (!refreshToken) {
                    return Promise.reject(new Error('Refresh token cannot be null'));
                }
                if (!accessToken) {
                    return Promise.reject(new Error('Access token cannot be null'));
                }
                if (!validator.isJWT(accessToken)) {
                    return Promise.reject(new Error('Invalid access token detected'));
                }
                if (this.tokenExpired(accessToken)) {
                    try {
                        accessToken = await this.newAccessToken();
                        if (!accessToken) {
                            return Promise.reject(new Error('Invalid access token detected'));
                        }
                        sessionStorage.setItem("accessToken", accessToken);
                    } catch(e) {
                        return Promise.reject(new Error('Fetching access token failed'));
                    }
                }
                const additionalHeaders = { authorization: 'Bearer ' + accessToken, app_version: 48 };
                return { ...config, headers: { ...config.headers, ...additionalHeaders } };
            })
        }
    }

    private tokenExpired = (accessToken: string) => {
        type DecodedType = {
            exp: number
        }
        const decoded = jwtDecode<DecodedType>(accessToken);
        return decoded.exp * 1000 <= Date.now();
    }

    private newAccessToken = async () => {
        const refreshToken = sessionStorage.getItem("refreshToken");
        const resp = await axios.post(process.env.REACT_APP_AUTH_SERVER_URL +  '/newAccessToken', { refreshToken });
        const accessToken = resp.data.data?.accessToken;
        return accessToken;
    }

    invokePOST = async (endPoint: Endpoints, params?: Object | undefined) => {
        try {
            const resp = await this.apiInstance.post(endPoint, params);
            if (resp.data.success) {
                return resp.data.data as T;
            } else {
                throw new Error(resp.data.message ?? 'Unknown error detected');
            }
        } catch(e) {
            throw e;
        }
    }

    invokeGET = async (endPoint: Endpoints, params?: Object | undefined) => {
        try {
            const resp = await this.apiInstance.get(endPoint, { params });
            if (resp.data.success) {
                return resp.data.data as T;
            } else {
                throw new Error(resp.data.message ?? 'Unknown error detected');
            }
        } catch(e) {
            throw e;
        }
    }
}

export default APIHandler;