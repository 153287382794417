import { useCallback, useEffect, useRef, useState } from 'react';
import { Container, Card, Row, Col, Alert, ButtonGroup, Button } from 'reactstrap';
import CurrentUsage from '../components/payment/CurrentUsage';
import PurchaseCLItems from '../components/payment/PurchaseCLItems';
import SubscriptionCard from '../components/payment/SubscriptionCard';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from "@stripe/react-stripe-js";
import PaymentModal from "../components/payment/PaymentModal";
import { useLocation } from 'react-router-dom';
import UserInfo from '../interfaces/UserInfo';
import APIHandler from '../classes/APIHandler';
import Subscription from '../interfaces/Subscription';
import { Endpoints } from '../consts/Endpoints';
import { useNavigate } from 'react-router-dom';
import AlertModal from '../components/AlertModal';
import PaymentHandler from '../classes/PaymentHandler';
import LoadingButton from '../components/LoadingButton';
import { SUBSCRIBE_TYPES } from '../consts/SubscribeOptions';
import PurchaseRelistingItems from '../components/payment/PurchaseRelistingItems';
import PurchaseMGItems from '../components/payment/PurchaseMGItems';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY as string);
type PurchaseItemRefHandler = {
    getItemCount: () => number
}

const Payment = () => {
    const [alert, setAlert] = useState({ crosslisting: '', relisting: '', magiscriptor: '' });
    const [subscriptionType, setSubscriptionType] = useState(SUBSCRIBE_TYPES.crosslisting);
    const [openPaymentModal, setOpenPaymentModal] = useState(false);
    const [userInfo, setUserInfo] = useState<UserInfo | undefined>();
    const [clSubscription, setCLSubscription] = useState<Subscription|undefined>(undefined);
    const [relistingSubscription, setRelistingSubscription] = useState<Subscription|undefined>(undefined);
    const [mgSubscription, setMGSubscription] = useState<Subscription|undefined>(undefined);
    const [showUnsubscribe, setShowUnsubscribe] = useState(false);
    const [unsubscribing, setUnsubscribing] = useState(false);
    const [unsubscribeErr, setUnsubscribeErr] = useState('');
    const [upgrading, setUpgrading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const purchaseCLItemRef = useRef<PurchaseItemRefHandler>(null);
    const purchaseRelistingItemRef = useRef<PurchaseItemRefHandler>(null);
    const purchaseMGItemRef = useRef<PurchaseItemRefHandler>(null);
    
    const getUserInfo = useCallback(async (userId: number) => {
        try {
            const baseUrl = sessionStorage.getItem("baseUrl") as string;
            const apiHandler = new APIHandler<UserInfo>(baseUrl);
            const userInfo = await apiHandler.invokeGET(Endpoints.GET_USER_DETAILS, { userId });
            setUserInfo(userInfo);
        } catch(e) {
            navigate('/');
            setUserInfo(undefined);
        }
    }, [navigate]);

    const getSubscriptionInfo = async (userId: number) => {
        try {
            const paymentHandler = new PaymentHandler<Subscription[]>();
            const subscriptions = await paymentHandler.getSubscription(userId);
            setCLSubscription(subscriptions.find(s => s.subscriptionType === SUBSCRIBE_TYPES.crosslisting));
            setRelistingSubscription(subscriptions.find(s => s.subscriptionType === SUBSCRIBE_TYPES.relisting));
            setMGSubscription(subscriptions.find(s => s.subscriptionType === SUBSCRIBE_TYPES.magiscriptor));
        } catch(e) {
            setCLSubscription(undefined);
            setRelistingSubscription(undefined);
            setMGSubscription(undefined);
        }
    };

    useEffect(() => {
        if (location.state && getUserInfo) {
            const userId = location.state as number;
            getUserInfo(userId);
            getSubscriptionInfo(userId);
        }
        if (location.search) {
            if (location.search.includes(SUBSCRIBE_TYPES.relisting)) {
                setSubscriptionType(SUBSCRIBE_TYPES.relisting);
            }
        }
    }, [location, getUserInfo])

    const togglePaymentModal = () => {
        setOpenPaymentModal(!openPaymentModal);
    }

    const onPurchaseCrosslisting = async () => {
        if (!userInfo?.id) return;
        if (clSubscription?.subscriptionId) {
            try {
                setUpgrading(true);
                const paymentHandler = new PaymentHandler();
                await paymentHandler.upgradeSubscription(userInfo?.id, clSubscription.subscriptionId, SUBSCRIBE_TYPES.crosslisting, purchaseCLItemRef.current?.getItemCount() as number)
                setUpgrading(false);
            } catch(e) {
                if (e instanceof Error) {
                    setAlert({ crosslisting: e.message, relisting: '', magiscriptor: '' });
                } else if (typeof e === 'string') {
                    setAlert({ crosslisting: e, relisting: '', magiscriptor: '' });
                }
                setUpgrading(false);
            }
        } else {
            setSubscriptionType(SUBSCRIBE_TYPES.crosslisting);
            setOpenPaymentModal(true);
        }
    }

    const onPurchaseRelisting = async () => {
        if (!userInfo?.id) return;
        if (relistingSubscription?.subscriptionId) {
            try {
                setUpgrading(true);
                const paymentHandler = new PaymentHandler();
                await paymentHandler.upgradeSubscription(userInfo?.id, relistingSubscription.subscriptionId, SUBSCRIBE_TYPES.relisting, purchaseRelistingItemRef.current?.getItemCount() as number)
                setUpgrading(false);
            } catch (e) {
                if (e instanceof Error) {
                    setAlert({ crosslisting: '', relisting: e.message, magiscriptor: '' });
                } else if (typeof e === 'string') {
                    setAlert({ crosslisting: '', relisting: e, magiscriptor: '' });
                }
                setUpgrading(false);
            }
        } else {
            setSubscriptionType(SUBSCRIBE_TYPES.relisting);
            setOpenPaymentModal(true);
        }
    }

    const onPurchaseMagiscriptor = async () => {
        if (!userInfo?.id) return;
        if (mgSubscription?.subscriptionId) {
            try {
                setUpgrading(true);
                const paymentHandler = new PaymentHandler();
                await paymentHandler.upgradeSubscription(userInfo?.id, mgSubscription.subscriptionId, SUBSCRIBE_TYPES.magiscriptor, purchaseMGItemRef.current?.getItemCount() as number)
                setUpgrading(false);
            } catch(e) {
                if (e instanceof Error) {
                    setAlert({ crosslisting: '', relisting: '', magiscriptor: e.message });
                } else if (typeof e === 'string') {
                    setAlert({ crosslisting: '', relisting: '', magiscriptor: e });
                }
                setUpgrading(false);
            }
        } else {
            setSubscriptionType(SUBSCRIBE_TYPES.magiscriptor);
            setOpenPaymentModal(true);
        }
    }

    const onCreatedSubscription = (subscriptionId: string, subscriptionType: string | undefined, itemCount: number, amountPaid: number) => {
        setOpenPaymentModal(false);
        if (userInfo?.id) {
            getSubscriptionInfo(userInfo?.id)
        }
        if (subscriptionType === SUBSCRIBE_TYPES.crosslisting) {
            setUserInfo(prev => {
                if (!prev) {
                    return undefined;
                }
                return { ...prev, totalCount: itemCount }
            })
        } else if (subscriptionType === SUBSCRIBE_TYPES.relisting) {
            setUserInfo(prev => {
                if (!prev) {
                    return undefined;
                }
                return { ...prev, relistingCount: itemCount }
            })
        } else if (subscriptionType === SUBSCRIBE_TYPES.magiscriptor) {
            setUserInfo(prev => {
                if (!prev) {
                    return undefined;
                }
                return { ...prev, mgTotalCount: itemCount }
            })
        }
    }

    const onUnsubscribe = () => {
        setShowUnsubscribe(true);
    }

    const onCouponApplied = () => {
        if (!userInfo?.id) return;
        getSubscriptionInfo(userInfo?.id);
        window.alert("Coupon Applied Successfully.")
    }

    const onRelistingUnsubscribe = () => {
        setShowUnsubscribe(true);
    }

    const onConfirmUnsubscribe = async (subscriptionType: string | undefined) => {
        try {
            if (!userInfo?.id) {
                return;
            }
            setUnsubscribing(true);
            const paymentHandler = new PaymentHandler();
            await paymentHandler.cancelSubscription(userInfo?.id, subscriptionType as string);
            setUnsubscribing(false);
            setShowUnsubscribe(false);
            if (subscriptionType === SUBSCRIBE_TYPES.crosslisting) {
                setCLSubscription(undefined);
            } else if (subscriptionType === SUBSCRIBE_TYPES.relisting) {
                setRelistingSubscription(undefined);
            } else if (subscriptionType === SUBSCRIBE_TYPES.magiscriptor) {
                setMGSubscription(undefined);
            }
        } catch(e) {
            if (e instanceof Error) {
                setUnsubscribeErr(e.message);
            } else if (typeof e === 'string') {
                setUnsubscribeErr(e);
            }
            setUnsubscribing(false);
        }
    }

    const isCLSubscriptionExpired = () => {
        if (!userInfo || !clSubscription) {
            return true;
        }
        return userInfo.totalCount <= userInfo.usedCount;
    }

    const isMGSubscriptionExpired = () => {
        if (!userInfo || !mgSubscription) {
            return true;
        }
        return userInfo.mgTotalCount <= userInfo.mgUsedCount;
    }

    const isRelistingExpired = () => {
        if (relistingSubscription) {
            return new Date(relistingSubscription.endAt) < new Date();
        }
        return false;
    }

    const isCLTrial = () => {
        return userInfo?.isTrial as Boolean
    }

    const isMGTrial = () => {
        if (!userInfo || mgSubscription) return false;
        return userInfo?.mgUsedCount < userInfo?.mgTotalCount;
    }

    const getPaymentItemCount = () => {
        if (subscriptionType === SUBSCRIBE_TYPES.crosslisting) {
            return purchaseCLItemRef.current?.getItemCount() as number;
        } else if (subscriptionType === SUBSCRIBE_TYPES.relisting) {
            return purchaseRelistingItemRef.current?.getItemCount() as number;
        } else if (subscriptionType === SUBSCRIBE_TYPES.magiscriptor) {
            return purchaseMGItemRef.current?.getItemCount() as number;
        }
        return 0;
    }

    return (
        <Elements stripe={stripePromise} options={{ fonts: [{ cssSrc: 'https://fonts.googleapis.com/css?family=Nunito:400' }] }}>
            <Container className='my-5'>
                <Row>
                    <Col lg='6' className='mx-auto'>
                        <ButtonGroup className='mb-4'>
                            <Button color='secondary' outline active={subscriptionType === SUBSCRIBE_TYPES.crosslisting} onClick={() => setSubscriptionType(SUBSCRIBE_TYPES.crosslisting)}>Crosslisting</Button>
                            <Button color='secondary' outline active={subscriptionType === SUBSCRIBE_TYPES.relisting} onClick={() => setSubscriptionType(SUBSCRIBE_TYPES.relisting)}>Relisting</Button>
                            <Button color='secondary' outline active={subscriptionType === SUBSCRIBE_TYPES.magiscriptor} onClick={() => setSubscriptionType(SUBSCRIBE_TYPES.magiscriptor)}>Magiscriptor</Button>
                        </ButtonGroup>
                        {
                            subscriptionType === SUBSCRIBE_TYPES.crosslisting &&
                            <div>
                                <SubscriptionCard 
                                    isExpired={isCLSubscriptionExpired() && !isCLTrial()} 
                                    isTrial={isCLTrial()} 
                                    amountPaid={clSubscription?.amountPaid}
                                    subscriptionId={clSubscription?.subscriptionId}
                                    totalCount={userInfo?.totalCount}
                                    paymentDetails={clSubscription?.paymentDetails}
                                    upcomingInvoice={clSubscription?.upcomingInvoice}
                                    subscriptionType={SUBSCRIBE_TYPES.crosslisting}
                                    onUnsubscribe={onUnsubscribe}
                                    onCouponApplied={onCouponApplied} />
                                <CurrentUsage 
                                    className='d-flex mt-4 border rounded p-4 align-items-start' 
                                    startedAt={clSubscription?.startedAt}
                                    endAt={clSubscription?.endAt}
                                    usedCount={userInfo?.usedCount} 
                                    totalCount={userInfo?.totalCount}
                                    />
                                <PurchaseCLItems 
                                    ref={purchaseCLItemRef} 
                                    subscriptionId={clSubscription?.subscriptionId}
                                    className='d-flex flex-column mt-4 justify-content-center' 
                                    totalCount={userInfo?.totalCount}  />
                                {
                                    !!alert.crosslisting && 
                                    <Alert color='danger' className='mb-0 mt-5' toggle={() => setAlert({ crosslisting: '', relisting: '', magiscriptor: '' })}>{alert.crosslisting}</Alert>
                                }
                                <LoadingButton loading={upgrading} className='w-50 mt-5 mx-auto' style={{ backgroundColor: '#3D5A80' }} onClick={onPurchaseCrosslisting}>
                                    {clSubscription?.subscriptionId ? 'Upgrade plan' : 'Subscribe plan'}
                                </LoadingButton>
                            </div>
                        }
                        {
                            subscriptionType === SUBSCRIBE_TYPES.relisting &&
                            <div>
                                {
                                    relistingSubscription && 
                                    <SubscriptionCard
                                        isExpired={isRelistingExpired()}
                                        isTrial={false}
                                        amountPaid={relistingSubscription?.amountPaid}
                                        totalCount={userInfo?.relistingCount}
                                        subscriptionId={relistingSubscription?.subscriptionId}
                                        upcomingInvoice={relistingSubscription?.upcomingInvoice}
                                        paymentDetails={relistingSubscription?.paymentDetails}
                                        subscriptionType={SUBSCRIBE_TYPES.relisting}
                                        onUnsubscribe={onRelistingUnsubscribe}
                                        onCouponApplied={onCouponApplied}
                                    />
                                }
                                <Card className='d-flex mt-4 border rounded p-4 align-items-center'>
                                    <h5 className='mb-3'>Relisting on Mercari</h5>
                                    <h5 className='mb-3'>Relisting on Depop</h5>
                                    <h5 className='mb-3'>Relisting on eBay</h5>
                                    <h5 className='mb-3'>Relisting on Kidizen</h5>
                                    <h5 className='mb-3'>Relisting on Grailed</h5>
                                    <h5 className='mb-3'>Relisting on Vinted</h5>
                                    <h5 className='mb-3'>Up to X relists per day / platform</h5>
                                    <h5 className='mb-3'>Free for Posh Sidekick subscribers</h5>
                                </Card>
                                <PurchaseRelistingItems 
                                    ref={purchaseRelistingItemRef}
                                    subscriptionId={relistingSubscription?.subscriptionId}
                                    className='d-flex flex-column mt-4 justify-content-center' 
                                    relistingCount={userInfo?.relistingCount}
                                />
                                {
                                    !!alert.relisting && 
                                    <Alert color='danger' className='mb-0 mt-5' toggle={() => setAlert({ crosslisting: '', relisting: '', magiscriptor: '' })}>{alert.relisting}</Alert>
                                }
                                <LoadingButton loading={upgrading} 
                                    className='w-50 mt-4 mx-auto' 
                                    style={{ backgroundColor: '#3D5A80' }} 
                                    onClick={onPurchaseRelisting}>
                                    {relistingSubscription?.subscriptionId ? 'Upgrade plan' : 'Subscribe plan'}
                                </LoadingButton>
                            </div>
                        }
                        {
                            subscriptionType === SUBSCRIBE_TYPES.magiscriptor &&
                            <div>
                                <SubscriptionCard 
                                    isExpired={isMGSubscriptionExpired() && !isMGTrial()} 
                                    isTrial={isMGTrial()} 
                                    amountPaid={mgSubscription?.amountPaid}
                                    totalCount={userInfo?.mgTotalCount}
                                    subscriptionId={mgSubscription?.subscriptionId}
                                    upcomingInvoice={mgSubscription?.upcomingInvoice}
                                    paymentDetails={mgSubscription?.paymentDetails}
                                    subscriptionType={SUBSCRIBE_TYPES.magiscriptor}
                                    onUnsubscribe={onUnsubscribe}
                                    onCouponApplied={onCouponApplied} />
                                <CurrentUsage 
                                    className='d-flex mt-4 border rounded p-4 align-items-start' 
                                    startedAt={mgSubscription?.startedAt}
                                    endAt={mgSubscription?.endAt}
                                    usedCount={userInfo?.mgUsedCount} 
                                    totalCount={userInfo?.mgTotalCount}
                                    />
                                <PurchaseMGItems 
                                    ref={purchaseMGItemRef} 
                                    subscriptionId={mgSubscription?.subscriptionId}
                                    className='d-flex flex-column mt-4 justify-content-center' 
                                    totalCount={userInfo?.mgTotalCount} />
                                {
                                    !!alert.magiscriptor && 
                                    <Alert color='danger' className='mb-0 mt-5' toggle={() => setAlert({ crosslisting: '', relisting: '', magiscriptor: '' })}>{alert.magiscriptor}</Alert>
                                }
                                <LoadingButton loading={upgrading} className='w-50 mt-5 mx-auto' style={{ backgroundColor: '#3D5A80' }} onClick={onPurchaseMagiscriptor}>
                                    {mgSubscription?.subscriptionId ? 'Upgrade plan' : 'Subscribe plan'}
                                </LoadingButton>
                            </div>
                        }
                    </Col>
                </Row>
                {
                    openPaymentModal &&
                    <PaymentModal 
                        userId={userInfo?.id}
                        isOpen={openPaymentModal}
                        subscriptionType={subscriptionType}
                        itemCount={getPaymentItemCount()}
                        toggle={togglePaymentModal}
                        onCreatedSubscription={onCreatedSubscription}
                        />
                }
            </Container>
            {
                showUnsubscribe &&
                <AlertModal 
                    title='Unsubscribe'
                    body='Are you sure to cancel the subscription?'
                    errorMsg={unsubscribeErr}
                    isOpen={showUnsubscribe}
                    inProgress={unsubscribing}
                    subscriptionType={subscriptionType}
                    onClickPositive={onConfirmUnsubscribe}
                    onClickNegative={() => setShowUnsubscribe(false)}
                />
            }
        </Elements>
    )
}

export default Payment;