export const SUBSCRIBE_CL_OPTIONS = [
    {
        count: 50,
        price: 999
    },
    {
        count: 150,
        price: 1999
    },
    {
        count: 300,
        price: 2999
    },
    {
        count: 600,
        price: 3999
    },
    {
        count: 2000,
        price: 8999
    },
    {
        count: 4000,
        price: 9999
    }
]

export const SUBSCRIBE_RELISTING_OPTIONS = [
    {
        count: 10,
        price: 599
    },
    {
        count: 15,
        price: 799
    },
    {
        count: 20,
        price: 999
    },
]

export const SUBSCRIBE_MG_OPTIONS = [
    {
        count: 100,
        price: 499
    },
    {
        count: 220,
        price: 999
    },
    {
        count: 330,
        price: 1499
    },
    {
        count: 450,
        price: 1999
    },
]

export const SUBSCRIBE_TYPES = {
    crosslisting: 'crosslisting',
    relisting: 'relisting',
    magiscriptor: 'magiscriptor'
}